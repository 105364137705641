define("additive-mi/services/ami-local-storage", ["exports", "@ember/service", "@glimmer/tracking"], function (_exports, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * wrapper service to access local storage
   *
   * @class AmiLocalStorage
   */
  let AmiLocalStorageService = _exports.default = (_class = class AmiLocalStorageService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiQueryParams", _descriptor, this);
      /**
       * the keys in the local storage used to get the current values
       *
       * @property _localStorageKeys
       * @type {Array}
       * @default []
       * @private
       */
      _initializerDefineProperty(this, "_localStorageKeys", _descriptor2, this);
      /**
       * the query params that are set
       * can be changed for each route individually
       *
       * @property _queryParams
       * @type {Array}
       * @default []
       */
      _initializerDefineProperty(this, "_queryParams", _descriptor3, this);
      this.uiQueryParams.register('filter', {
        start: {},
        end: {},
        dateFilter: {},
        reservationTypes: {},
        hiddenColumnsAnalytics: {},
        groupingHierarchy: {},
        groups: {},
        attributionMode: {},
        conversionType: {},
        sessionBasis: {}
      });
    }
    willDestroy() {
      this.uiQueryParams.unregister('filter');
    }

    /**
     * sets the current local storage key
     *
     * @function setLocalStorageKeys
     * @param {Array} localStorageKeys
     */
    setLocalStorageKeys(localStorageKeys = []) {
      this._localStorageKeys = localStorageKeys;
    }

    /**
     * updates the query params of the current route
     *
     * @function setQueryParams
     * @param {Array} queryParams
     */
    setQueryParams(queryParams = []) {
      this._queryParams = queryParams;
      if (!queryParams || queryParams.length === 0) {
        this.uiQueryParams.reset('filter');
        return;
      }
      queryParams.forEach(key => {
        this._localStorageKeys.some(localStorageKey => {
          if (this.getValue(localStorageKey)[key]) {
            this.uiQueryParams.setValue('filter', key, this.getValue(localStorageKey)[key]);
            return;
          }
        });
      });
    }

    /**
     * sets a value with a given key in the local storage
     *
     * @function setValue
     * @param {String} key
     * @param {Object} value
     */
    setValue(key, value, overwriteQueryParams = true) {
      if (window && window.localStorage) {
        const currentValue = JSON.parse(window.localStorage.getItem(key) || '{}');
        const newValue = Object.assign(currentValue, value);
        window.localStorage.setItem(key, JSON.stringify(newValue));
      }
      if (overwriteQueryParams) {
        Object.keys(value).forEach(key => {
          if (this._queryParams.indexOf(key) !== -1) {
            this.uiQueryParams.setValue('filter', key, value[key]);
          }
        });
      }
    }

    /**
     * gets the value from local storage
     *
     * @function getStoredValue
     * @param {String} key
     * @return {Object} value
     */
    getStoredValue(key) {
      if (window && window.localStorage) {
        const value = window.localStorage.getItem(key);
        return value && JSON.parse(value) || {};
      }
    }

    /**
     * gets the value from local storage
     * considers current query parameters
     *
     * @function getValue
     * @param {String} localStorageKey
     * @return {Object} value
     */
    getValue(localStorageKey) {
      const value = this.getStoredValue(localStorageKey);
      return Object.keys(value).filter(key => this._queryParams.indexOf(key) !== -1).reduce((result, key) => {
        let queryParam = this.uiQueryParams.getValue('filter', key);
        if (queryParam && ['start', 'end', 'dateFilter', 'conversionType', 'attributionMode', 'sessionBasis'].indexOf(key) === -1 && typeof queryParam === 'string') {
          queryParam = [queryParam];
        } else if (key === 'conversionType' && Array.isArray(queryParam)) {
          queryParam = queryParam.join(',');
        }
        result[key] = queryParam || value[key];
        return result;
      }, {});
    }

    /**
     * clears the local storage
     *
     * @function clear
     */
    clear() {
      this._localStorageKeys?.forEach(localStorageKey => {
        window && window.localStorage && window.localStorage.removeItem(localStorageKey);
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiQueryParams", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_localStorageKeys", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_queryParams", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _class);
});