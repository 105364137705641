define("additive-mi/components/ami-notifications", ["exports", "@ember/component", "@ember/template-factory", "additive-mi/config/environment", "@glimmer/component", "@glimmer/tracking", "ember-concurrency", "@ember/service"], function (_exports, _component, _templateFactory, _environment, _component2, _tracking, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.alerts.length}}
    {{#each this.alerts as |alert|}}
      <UiPopover as |popup|>
        <popup.trigger>
          <UiInlineNotification
            @icon="warning"
            @isError={{eq alert.severity "high"}}
            @isWarning={{eq alert.severity "medium"}}
            @title={{concat
              (t (concat "components.amiNotifications.alertTypes." alert.category))
              (format-date-time alert.createdAt this.currentUser.user.language)
            }}
          />
        </popup.trigger>
        <popup.content>
          <div class="font-sm pv2">
            {{html-safe alert.message}}
          </div>
        </popup.content>
      </UiPopover>
    {{/each}}
  {{/if}}
  */
  {
    "id": "/xWFRadm",
    "block": "[[[41,[30,0,[\"alerts\",\"length\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"alerts\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@icon\",\"@isError\",\"@isWarning\",\"@title\"],[\"warning\",[28,[37,5],[[30,1,[\"severity\"]],\"high\"],null],[28,[37,5],[[30,1,[\"severity\"]],\"medium\"],null],[28,[37,6],[[28,[37,7],[[28,[37,6],[\"components.amiNotifications.alertTypes.\",[30,1,[\"category\"]]],null]],null],[28,[37,8],[[30,1,[\"createdAt\"]],[30,0,[\"currentUser\",\"user\",\"language\"]]],null]],null]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"font-sm pv2\"],[12],[1,\"\\n          \"],[1,[28,[35,9],[[30,1,[\"message\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[1]],null]],[]],null]],[\"alert\",\"popup\"],false,[\"if\",\"each\",\"-track-array\",\"ui-popover\",\"ui-inline-notification\",\"eq\",\"concat\",\"t\",\"format-date-time\",\"html-safe\"]]",
    "moduleName": "additive-mi/components/ami-notifications.hbs",
    "isStrictMode": false
  });
  let AcrmNotifications = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    try {
      const baseUrl = `${_environment.default.APP.apiBaseHost}/${this.currentUser.currentOrganization.id}`;
      const response = yield this.authenticatedFetch.fetch(`${baseUrl}/alerts`);
      if (!response?.ok) throw response;
      const json = yield response.json();
      this.alerts = json?.alerts;
    } catch (error) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }), _class = class AcrmNotifications extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "uiToast", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor4, this);
      _initializerDefineProperty(this, "alerts", _descriptor5, this);
      _initializerDefineProperty(this, "fetchAlerts", _descriptor6, this);
      this.fetchAlerts.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "alerts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fetchAlerts", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcrmNotifications);
});