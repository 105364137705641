define("additive-mi/controllers/instance/dashboard/index", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed", "date-fns", "@ember/object"], function (_exports, _controller, _service, _computed, _dateFns, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5; // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceInsightsIndexController = _exports.default = (_dec = (0, _computed.not)('uiAppSettings.isProfessionalPlan'), _class = class InstanceInsightsIndexController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "uiState", _descriptor4, this);
      _initializerDefineProperty(this, "_showUpgradeBanner", _descriptor5, this);
    }
    get _organizationSlug() {
      return this.currentUser.currentOrganization.id;
    }
    get _maxFilterDate() {
      return new Date();
    }
    get salutation() {
      const time = (0, _dateFns.format)(new Date(), 'HH:mm');
      let salutation;
      let name = this.currentUser?.user?.fullName?.split(' ')[0];
      name == undefined ? name = '' : name = `, ${name}`;
      time > '00:00' && time <= '12:00' ? salutation = this.intl.t(`dashboard.salutations.morning`) : time > '12:00' && time < '17:00' ? salutation = this.intl.t(`dashboard.salutations.afternoon`) : salutation = this.intl.t(`dashboard.salutations.evening`);
      return `${salutation}${name}!`;
    }
    toggleDetail() {
      this.uiState.getState('detail').toggle();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_showUpgradeBanner", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleDetail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDetail"), _class.prototype), _class);
});