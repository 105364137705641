define("additive-mi/controllers/instance/settings/snippet", ["exports", "additive-mi/config/environment", "@ember/controller", "ember-concurrency", "@ember/service", "@glimmer/tracking", "@ember/object", "ember-changeset", "ember-changeset-validations"], function (_exports, _environment, _controller, _emberConcurrency, _service, _tracking, _object, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceSettingsSnippet = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    try {
      const adapter = this.store.adapterFor('snippet');
      const url = adapter.buildURL();
      const response = yield this.authenticatedFetch.fetch(url);
      if (!response?.ok) throw response;
      const json = yield response.json();
      this.snippet = json.snippetSettings;
      this._sdkVersionOptions = json.meta?.sdkVersions?.map(version => {
        return {
          label: version,
          value: version
        };
      });
      this._cookieLevelOptions = json.meta?.cookieLevels?.map(level => {
        return {
          label: this.intl.t(`settings.tracking.configuration.cookieLevel.options.${level}`),
          value: level
        };
      });
      this.changeset = new _emberChangeset.default(this.snippet, (0, _emberChangesetValidations.default)(true), true);
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec2 = (0, _emberConcurrency.task)(function* () {
    this.changeset.set('analyticsActive', !this.changeset.get('analyticsActive'));
    yield this.save.perform();
  }), _dec3 = (0, _emberConcurrency.task)(function* () {
    try {
      if (!this.changeset.get('isValid')) return;
      this.changeset.execute();
      const adapter = this.store.adapterFor('snippet');
      const url = adapter.buildURL();
      const body = {
        snippetSettings: this.changeset.get('data')
      };
      const response = yield this.authenticatedFetch.fetch(url, {
        method: 'PUT'
      }, body);
      if (!response?.ok) throw response;
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.success')
      });
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _class = class InstanceSettingsSnippet extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor5, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor6, this);
      _initializerDefineProperty(this, "uiToast", _descriptor7, this);
      _initializerDefineProperty(this, "isSnippetCodeModalOpen", _descriptor8, this);
      _initializerDefineProperty(this, "changeset", _descriptor9, this);
      _initializerDefineProperty(this, "snippet", _descriptor10, this);
      _initializerDefineProperty(this, "_cookieLevelOptions", _descriptor11, this);
      _initializerDefineProperty(this, "_sdkVersionOptions", _descriptor12, this);
      /**
       * Fetch settings
       *
       * @function loadSettings
       * @type {Task}
       */
      _initializerDefineProperty(this, "loadSettings", _descriptor13, this);
      _initializerDefineProperty(this, "toggleAnalyticsActive", _descriptor14, this);
      _initializerDefineProperty(this, "save", _descriptor15, this);
    }
    get guideUrl() {
      const language = this.uiLocale?.locale || 'de';
      return `${_environment.default.APP.cdnHost}/${_environment.default.APP.awsBucket}/marketing-insights/snippet-integration-${language}.pdf`;
    }
    get _readOnly() {
      // if not managed, only managers and admins can edit
      if (!this.uiAppSettings?.managed) {
        return !this.currentUser.isManager;
      }

      // if managed by partner, only additive and partner users can edit
      if (this.uiAppSettings?.managedBy === 'parnter') {
        return !this.currentUser.isAdditiveUser && !this.currentUser.isPartnerUser;
      }

      // if managed by additive, only additive users can edit
      return !this.currentUser.isAdditiveUser;
    }
    get _cookiesInstructionPDF() {
      const PDF_DE = 'https://drive.google.com/file/d/1mR-FR7Z3lXcFOykZrLRTgFZuftOB-Vgh/view';
      const PDF_EN = 'https://drive.google.com/file/d/1KvM8TjbvOfDQVi5dKy8zgmSonpVI24II/view';
      if (this.currentUser?.user?.language === 'de') {
        return PDF_DE;
      }
      return PDF_EN;
    }
    setProperty(property, value) {
      this.changeset.set(property, value);
    }
    copyToClipboard(text) {
      navigator?.clipboard?.writeText(text);
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('settings.tracking.copySuccess')
      });
    }
    resetChangeset() {
      this.changeset.rollback();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isSnippetCodeModalOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "snippet", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_cookieLevelOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_sdkVersionOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "loadSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "toggleAnalyticsActive", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "save", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setProperty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setProperty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyToClipboard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copyToClipboard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetChangeset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetChangeset"), _class.prototype), _class);
});